@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
/*
 * Note that this is toastr v2.1.3, the "latest" version in url has no more maintenance,
 * please go to https://cdnjs.com/libraries/toastr.js and pick a certain version you want to use,
 * make sure you copy the url from the website since the url may change between versions.
 * */
.toast-title {
  font-weight: bold; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  color: #FFFFFF;
  font-size: 18px;
  opacity: 1; }

.toast-message a,
.toast-message label {
  color: #FFFFFF; }

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none; }

.toast-close-button {
  position: relative;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  font-size: 30px;
  font-weight: 300;
  color: #656565;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1; }

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em; }

/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/ }

#toast-container * {
  box-sizing: border-box; }

#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  height: 71px;
  display: flex;
  align-items: center;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  color: #FFFFFF;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center; }

#toast-container > div:hover {
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer; }

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto; }

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #D0FBE9; }
  .toast-success .toast-message {
    color: #06472C; }

.toast-error {
  background-color: #FDE7E9; }
  .toast-error .toast-message {
    color: #760913; }

.toast-info {
  background-color: #E0F6FF; }
  .toast-info .toast-message {
    color: #00567A; }

.toast-warning {
  background-color: #f7bc63; }
  .toast-warning .toast-message {
    color: #F89406; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 15px 15px 15px 15px;
    width: 18em; }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px; }
  #toast-container .toast-close-button {
    right: 0.5em;
    top: -0.2em; }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em; }
  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px; } }

h1,
h2,
h3,
h4,
h5 {
  margin: 0; }

ol,
ul {
  margin: 0;
  list-style: none; }

html,
body {
  height: 100%;
  width: 100%;
  position: relative; }

#root {
  height: 100%;
  background-color: #fcfcfc; }

b {
  font-weight: bold; }

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  font-style: normal;
  color: #757575;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text; }

*:not(i) {
  font-family: "Source Sans Pro", sans-serif !important; }

.__react_component_tooltip {
  overflow-wrap: break-word !important;
  white-space: pre-wrap !important; }

form {
  background-color: transparent; }

