@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i");

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

ol,
ul {
    margin: 0;
    list-style: none;
}

html,
body {
    height: 100%;
    width: 100%;
    position: relative;
}

#root{
    height: 100%;
    background-color: hsla(0,0%,99%,1);
}

b {
    font-weight: bold;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    font-style: normal;
    color: #757575;
    user-select: text;
}

*:not(i) {
    font-family: "Source Sans Pro", sans-serif !important;
}

.__react_component_tooltip{
    overflow-wrap: break-word !important;
    white-space: pre-wrap !important;
}

form {
    background-color: transparent;
}
